import textNote from "../../images/banner-card/text-note.svg";
import reduceHealth from "../../images/banner-card/reduce-health.svg";
import hospital from "../../images/banner-card/hospital.svg";

const BannerData = [
  {
    icon: reduceHealth,
    header: "Take Care of your Overall Health",
    desc: "Through CCC, you can get the best care for your chronic conditions and other primary healthcare issues.",
  },
  {
    icon: textNote,
    header: "Skip the Waiting Room",
    desc: "CCC uses a combination of technology and human resources to provide you with the best care.",
  },
  {
    icon: hospital,
    header: "Get the Care You Need",
    desc: "We are highly qualified in the care of patients with chronic diseases that can cause predisposition to heart disease",
  },
];

export default BannerData;
