import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "./components/Shared/Header/Header";
import Footer from "./components/Shared/Footer/Footer";
import TopHeader from "./components/Shared/TopHeader/TopHeader";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Notfound from "./components/Notfound/Notfound";
import Contact from "./pages/Contact/Contact";
import Services from "./pages/Services/Services";
import Staff from "./pages/Staff/Staff";
function App() {
  return (
    <Router>
      <TopHeader />
      <Header />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/home">
          <Home />
        </Route>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/services">
          <Services />
        </Route>
        <Route path="/staff">
          <Staff />
        </Route>
        <Route path="*">
          <Notfound />
        </Route>
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
