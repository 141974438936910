import img1 from "../../images/patient-3.jpeg";

const OurServicesData = () => {
  const icon1 = <i className="fas fa-heart"></i>;
  const icon2 = <i className="fas fa-shield-alt"></i>;
  const icon3 = <i className="fas fa-book-medical"></i>;
  const icon4 = <i className="fas fa-users-cog"></i>;
  const icon5 = <i className="fas fa-user-md"></i>;
  const icon6 = <i className="fas fa-dna"></i>;

  return [
    {
      id: 4234,
      icon: icon1,
      name: "Hypertension",
      desc: "Hypertension significantly increases the risk of heart, brain and kidney diseases, and is one of the top causes of death throughout the world.",
      img: img1,
    },
    {
      id: 5323,
      icon: icon2,
      name: "High Cholesterol",
      desc: "High cholesterol is a risk factor for heart disease, stroke, and other cardiovascular diseases. Nearly 94 million Americans have high cholesterol levels.",
      img: img1,
    },
    {
      id: 7560,
      icon: icon3,
      name: "Type 2 Diabetes",
      // information about Type 2 Diabetes
      desc: "Type 2 diabetes is a chronic form of diabetes that affects people of all ages, but can be effectively handled with the proper care and control. ",
      img: img1,
    }
  ];
};

export default OurServicesData;
