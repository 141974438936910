import aboutImg from "../../images/patient-3.jpeg";

const SolutionsData = {
  flexReverse: "flex-row-reverse",
  name: "Solutions",
  img: aboutImg,
  header: "Telehealth Virtual Health Care Solutions",
  desc: <p>
    We strongly believe that  there are always  several ways to find solutions to chronic health problems. Complete connected care work with patients to make lifestyle changes before starting medications.
    <br />
    <br />
    <h6>Hours: </h6>
    Mon-Fri: 7 PM to 10 PM
    <br />
    Sat: 10 AM to 4 PM
    <br />
    Sun: 1 PM to 5 PM
    <br />
    <br />

    <b>Cash / Direct Payment only.</b>
  </p>,
};

export default SolutionsData;
