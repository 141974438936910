import { Col, Container, Row } from "react-bootstrap";
import OurStaffData from "./OurStaffData";
import "./OurStaffData";
import CommonSectionHeader from "../CommonSectionHeader/CommonSectionHeader";
import { Fade } from "react-reveal";

const OurStaff = ({ services }) => {
  const ourStaffData = OurStaffData();

  const style = {
    color: services ? "black" : "",
  };

  const data = {
    headline: "Our Staff",
    header: "Helping you get the best service",
  };

  return (
    <>
      <div className="service_img"></div>
      <div className="service_container">
        <Container>
          <Fade bottom distance="60px" duration={3000}>
            <CommonSectionHeader
              style={style}
              data={data}
            ></CommonSectionHeader>
            <Row>
              {ourStaffData.map((item, ind) => {
                const { id, icon, name, desc, img } = item;
                return (
                  <Col className="service_item" sm="12" md="6" lg="6" key={id}>
                    <div className="service_card">
                      <img src={img} />
                      <h2>{name}</h2>
                      <p>{desc}</p>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Fade>
        </Container>
      </div>
    </>
  );
};

export default OurStaff;
