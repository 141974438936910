import { Col, Container, Row } from "react-bootstrap";
import OurServicesData from "./OurServicesData";
import "./OurServices.css";
import CommonSectionHeader from "../CommonSectionHeader/CommonSectionHeader";
import { Fade } from "react-reveal";
import Solutions from "../Solutions/Solutions";

const OurServices = ({ services }) => {
  const ourServiceData = OurServicesData();

  const style = {
    color: services ? "black" : "",
  };

  const data = {
    headline: "Our Services",
    header: "Our Healthcare Services",
    desc: "We provide high quality care for all of the following conditions:",
  };

  return (
    <>
      <div className='service_img'></div>
      <div className='service_container'>
        <Container>
          <Fade bottom distance='60px' duration={3000}>
            <CommonSectionHeader style={style} data={data}></CommonSectionHeader>
            <Row>
              {ourServiceData.map((item, ind) => {
                const { id, icon, name, desc } = item;
                console.log("{ourServiceData.map -> id", id);
                let background = "";
                if (id === 4234) {
                  background = "hypertension";
                }
                if (id === 7560) {
                  background = "type2diabetes";
                }
                return (
                  <Col className='service_item' sm='12' md='6' lg='4' key={id}>
                    <div className={`service_card ${background}`}>
                      <div className='service_icon'>{icon}</div>
                      <h2>{name}</h2>
                      <p>{desc}</p>
                    </div>
                  </Col>
                );
              })}
              <div style={{ marginBottom: 25, marginTop: -25 }}>
                <ul className='solutions_features'>
                  <li>
                    <span>Acute Visit: $50/visit</span>
                  </li>
                  <li>
                    <span>Medication Refill: $25/visit</span>
                  </li>
                  <li>
                    <span>Chronic Care Management: $100/month</span>
                  </li>
                  <li>
                    <span>Free style Dance Fitness: $25/session</span>
                  </li>
                  <li>
                    <span>Heart Healthy Yoga Classes: $25/session</span>
                  </li>
                  <li>
                    <span>Fine Lines and Wrinkles:</span>
                    <ul>
                      <li>Younger Age Group (18-40): $100 per session (30 minutes)</li>
                      <li>Middle Age Group (41-60): $220 per session (40 minutes)</li>
                      <li>Older Age Group (61+): $340 per session (50 minutes)</li>
                    </ul>
                  </li>
                  <li>
                    <span>Stretch Marks:</span>
                    <ul>
                      <li>Younger Age Group (18-40): $100 per session (30 minutes)</li>
                      <li>Middle Age Group (41-60): $220 per session (30 minutes)</li>
                      <li>Older Age Group (61+): $340 per session (40 minutes)</li>
                    </ul>
                  </li>
                  <li>
                    <span>Dark Spots and Hyperpigmentation:</span>
                    <ul>
                      <li>Younger Age Group (18-40): $150 per session (30 minutes)</li>
                      <li>Middle Age Group (41-60): $250 per session (40 minutes)</li>
                      <li>Older Age Group (61+): $400 per session (50 minutes)</li>
                    </ul>
                  </li>
                  <li>
                    <span>Loose Skin:</span>
                    <ul>
                      <li>Younger Age Group (18-40): $100 per session (30 minutes)</li>
                      <li>Middle Age Group (41-60): $320 per session (40 minutes)</li>
                      <li>Older Age Group (61+): $440 per session (50 minutes)</li>
                    </ul>
                  </li>
                  <li>
                    <span>Scars and Large Pores:</span>
                    <ul>
                      <li>Younger Age Group (18-40): $100 per session (10 minutes)</li>
                      <li>Middle Age Group (41-60): $120 per session (20 minutes)</li>
                      <li>Older Age Group (61+): $140 per session (25 minutes)</li>
                    </ul>
                  </li>
                  <li>
                    <span>Brightening Facial:</span>
                    <ul>
                      <li>Age group starting from 25-65: $150-$180 per session (1 hour)</li>
                    </ul>
                  </li>
                  <li>
                    <span>Hydrating Facial:</span>
                    <ul>
                      <li>Any age group: $120-$160 per session (1 hour)</li>
                    </ul>
                  </li>
                  <li>
                    <span>Firming and Contouring Facial:</span>
                    <ul>
                      <li>Age group starting from 30-75: $200-$250 per session (1 hour 20 minutes)</li>
                    </ul>
                  </li>
                </ul>
              </div>
              <h2>Why do Chronic Conditions Matter?</h2>
              <div style={{ color: "#6d6d6d" }}>
                <p>
                  HTN: High blood pressure increases the risk for heart disease and stroke, two leading causes of death for Americans.Tens of millions of adults in the United
                  States have high blood pressure, and many do not have it under control. About half of adults (45%) with uncontrolled hypertension have a blood pressure of 140/90
                  mmHg or higher. This includes 37 million U.S. adults.Only about 1 in 4 adults (24%) with hypertension have their condition under control
                </p>
                <p>
                  High cholesterol is a major and modifiable risk factor for heart disease and stroke, the leading and fifth-leading causes of death in the United States,
                  respectively. High total cholesterol (≥ 240 mg/dL) doubles the risk of heart disease, including heart attack. Excess low-density lipoprotein cholesterol (“bad”
                  cholesterol) creates a plaque that narrows arteries and reduces oxygen-rich blood flow. When a coronary artery is blocked, the result is a heart attack. In
                  addition, restricted blood flow to the brain can cause a stroke. Slightly more than half of U.S. adults (54.5%, or 47 million) who could benefit from cholesterol
                  medicine are currently taking it. Nearly 94 million U.S. adults age 20 or older have total cholesterol levels higher than 200 mg/dL , as per CDC.
                </p>
                <p>
                  Type 2 Diabetes Mellitus : More than 37 million Americans have diabetes (about 1 in 10), and approximately 90-95% of them have type 2 diabetes. Type 2 diabetes
                  most often develops in people over age 45, but more and more children, teens, and young adults are also developing it. Unlike many health conditions, diabetes is
                  managed mostly by you, with support from your health care team.Managing diabetes can be challenging, but everything you do to improve your health is worth it!
                  Thts why we are here to help you
                </p>
                <p>
                  Gastroesophageal reflux disease (GERD) is a chronic gastrointestinal disorder characterized by the regurgitation of gastric contents into the esophagus. It is one
                  of the most commonly diagnosed digestive disorders in the US with a prevalence of 20%, resulting in a significant economic burden in direct and indirect costs and
                  adversely affects the quality of life
                </p>
                <p>That is our ultimate mission at complete connected care, providing the best patient care experience!</p>
              </div>
            </Row>
          </Fade>
        </Container>
      </div>
    </>
  );
};

export default OurServices;
