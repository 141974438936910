import CommonHeader from "../../components/CommonHeader/CommonHeader";
import img from "../../images/about.png";
import Abouts from "../../components/About/About";
import imgs from "../../images/about.png";
import "./About.css";

const Staff = () => {
  return (
    <>
      <div className="about_container">
        <CommonHeader img={img} header="About"></CommonHeader>
      </div>
      <Abouts img={imgs} isPage={true}></Abouts>
    </>
  );
};

export default Staff;
