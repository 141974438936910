import "./TopHeader.css";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

const TopHeader = () => {
  return (
    <div className="top_banner">
      <Container className="d-flex justify-content-between">
        <div className="contact_details">
          <div className="contact_details_items">
            <div className="contact_details_icon">
              <i className="far fa-clock"></i>
            </div>
            <div className="contact_details_text"><span style={{ marginRight: 10 }}>M-F: 7:00 PM to 10:00 PM</span> <span style={{ marginRight: 10 }}>S: 10:00 AM to 4:00 PM </span> Su: 1:00 PM to 5:00 PM</div>
          </div>
          <div className="contact_details_items">
            <div className="contact_details_icon">
              <i className="fas fa-headset"></i>
            </div>
            <div className="contact_details_text">{"Call Us: +1 (904)-761-6927"}</div>
          </div>
          <div className="contact_details_items">
            <div className="contact_details_icon">
              <i className="fab fa-telegram-plane"></i>
            </div>
            <div className="contact_details_text">info@completeconnectedcare.com</div>
          </div>
        </div>
        <div className="social_icons_signin">
          <div className="social_links">
            <div className="social_icon" onClick={() => {
              window.open("https://www.facebook.com/completeconnectedcare", "_blank");
            }}>
              <i className="fab fa-facebook-f"></i>
            </div>
            <div className="social_icon" onClick={() => {
              window.open("https://www.instagram.com/completeconnectedcare/", "_blank");
            }}>
              <i className="fab fa-instagram"></i>
            </div>
          </div>
        </div>
      </Container >
    </div >
  );
};

export default TopHeader;
