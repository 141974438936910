import { Col, Row } from "react-bootstrap";
import Common from "../Common/Common";
import AboutData from "./AboutData";
import checkMark from "../../images/check-mark.svg";
import "./About.css";

const About = ({ img, isPage }) => {
  const aboutItemData = [
  ];
  return (
    <>
      <div className="Common_container">
        <Common data={AboutData} imgs={img}>
          <Row className="about_items">
            {aboutItemData.map((item, ind) => (
              <Col lg="6" key={ind}>
                <div className="about_item">
                  <img src={checkMark} alt="" />
                  <h5>{item.services}</h5>
                </div>
              </Col>
            ))}
            {isPage &&
              <>
                <h6 style={{
                  color: "rgb(33, 37, 41)",
                }}>
                  What is Telehealth?
                </h6>
                <p style={{
                  color: "#7b7c93"
                }}>
                  Telemedicine (or telehealth) represents a revolutionary patient management approach combining various forms of information communication technology (ICT) to remotely deliver care, consultation, medical education, specific healthcare and clinical services, and for monitoring patients’ parameters at distance. It is a promising tool for improving access to care, empowering patients, influencing their attitudes and behaviors, and ultimately enhancing their medical conditions.
                </p>
              </>
            }
          </Row>
        </Common>
      </div>
    </>
  );
};

export default About;
