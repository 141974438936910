import { Col, Container, Row } from "react-bootstrap";
import "./Footer.css";
import img1 from "../../../images/dr/image2.jpg";
import img2 from "../../../images/dr/image1 (1).jpg";
import img3 from "../../../images/dr/image2.jpg";
import img4 from "../../../images/dr/image3.jpg";
import img5 from "../../../images/dr/image4.jpg";
import img6 from "../../../images/dr/image4.jpg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer_container">
      <Container>
        <Row>
          <Col lg="6" md="4" sm="6">
            <div className="single-footer-widget">
              <h3>Complete Connected Care</h3>
              <p>
                Available to provide complete connected care to all those who need it in the Florida region.
                Keep up with us on Social Media for more information.
                <br />
                M-F: 7:00 PM to 10:00 PM S: 10:00 AM to 4:00 PM Su: 1:00 PM to 5:00 PM
              </p>
              <ul className="footer-social">
                <li className="social_icon" onClick={() => {
                  window.open("https://www.facebook.com/completeconnectedcare", "_blank");
                }}>
                  <i className="fab fa-facebook-f"></i>
                </li>
                <li className="social_icon" onClick={() => {
                  window.open("https://www.instagram.com/completeconnectedcare/", "_blank");
                }}>
                  <i className="fab fa-instagram"></i>
                </li>
              </ul>
            </div>
          </Col>
          <Col lg="6" md="4" sm="6" style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}>
            <div className="single-footer-widget">
              <h3>Contact</h3>
              <div className="footer-info-contact">
                <i className="fas fa-phone"></i>
                <h3>Phone</h3>
                <span>
                  <a href="tel:9047616927">{"Call Us: +1 (904)-761-6927"}</a>
                </span>
              </div>
              <div className="footer-info-contact">
                <i className="fas fa-envelope"></i>
                <h3>Email</h3>
                <span>
                  <a href="mailto:info@completeconnectedcare.com">info@completeconnectedcare.com</a>
                </span>
              </div>
              <div className="footer-info-contact">
                <i className="fas fa-fax"></i>
                <h3>Fax</h3>
                <span>
                  <a href="fax:888-251-1341">888-251-1341</a>
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
