import aboutImg from "../../images/profile-5.png";

const AboutData = {
  flexReverse: "",
  name: "About Us",
  img: aboutImg,
  header:
    "At Complete Connected Care, we believe in health care that is customized to our patient’s needs.",
  desc:
    <p>
      Complete connected care is owned and operated by <b>Sunitha Menon</b>, a Board Certified Family Nurse Practitioner who has graduated with honors and with several years of experience working with state and federal regulations.
      <br />
      <br />
      Our primary focus is on treating <b>Hypertension</b>, <b>High Cholesterol</b>, <b>Type 2 Diabetes</b> and <b>GERD</b>. Currently serving adult and geriatric population residing in Florida. This is a completely cash based service with no hidden costs.
    </p>
};

export default AboutData;
