import { Col, Container, Row } from "react-bootstrap";
import { Fade } from "react-reveal";
import "./Banner.css";
import bannerImg from "../../images/profile-1.jpeg";
import { Link } from "react-router-dom";

const Banner = () => {
  return (
    <div className='banner'>
      <Container>
        <div className='main_banner'>
          <Row className='align-items-center'>
            <Col sm='12' lg='6' className='banner_content'>
              <Fade left distance='60px' duration={3000}>
                <h1>
                  <b style={{ fontSize: "larger" }}>C</b>omplete <b style={{ fontSize: "larger" }}>C</b>onnected <b style={{ fontSize: "larger" }}>C</b>are: <br />
                  Telehealth Services
                </h1>
                <br />
                <h4>
                  High quality healthcare from the comfort of your own home. Fast. Easy. Affordable. <br />
                </h4>
                <br />
                <div className='make_an_appointment'>
                  <a href='tel:9047616927'>{"Call +1 (904)-761-6927"}</a>
                  <i className='fas fa-phone'></i>
                </div>
              </Fade>
            </Col>
            <Col sm='12' lg='6'>
              <Fade right distance='60px' duration={3000}>
                <div className='banner_img_div'>
                  <img className='banner_img' src={bannerImg} alt='' />
                </div>
              </Fade>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default Banner;
