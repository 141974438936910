import "./Solutions.css";
import Common from "../Common/Common";
import SolutionsData from "./SolutionsData";

const Solutions = (props) => {
  return (
    <div className="solution_container">
      <Common data={SolutionsData}>
        <ul className="solutions_features">
          <li>
            <span>Acute Visit: $50/visit</span>
          </li>
          <li>
            <span>Medication Refill: $25/visit</span>
          </li>
          <li>
            <span>Free style Dance Fitness: $25/session</span>
          </li>
          <li>
            <span>Heart Healthy Yoga Classes: $25/session</span>
          </li>
          <li>
            <span>Fine Lines and Wrinkles:</span>
            <ul>
              <li>Younger Age Group (18-40): $100 per session (30 minutes)</li>
              <li>Middle Age Group (41-60): $220 per session (40 minutes)</li>
              <li>Older Age Group (61+): $340 per session (50 minutes)</li>
            </ul>
          </li>
          <li>
            <span>Stretch Marks:</span>
            <ul>
              <li>Younger Age Group (18-40): $100 per session (30 minutes)</li>
              <li>Middle Age Group (41-60): $220 per session (30 minutes)</li>
              <li>Older Age Group (61+): $340 per session (40 minutes)</li>
            </ul>
          </li>
          <li>
            <span>Dark Spots and Hyperpigmentation:</span>
            <ul>
              <li>Younger Age Group (18-40): $150 per session (30 minutes)</li>
              <li>Middle Age Group (41-60): $250 per session (40 minutes)</li>
              <li>Older Age Group (61+): $400 per session (50 minutes)</li>
            </ul>
          </li>
          <li>
            <span>Loose Skin:</span>
            <ul>
              <li>Younger Age Group (18-40): $100 per session (30 minutes)</li>
              <li>Middle Age Group (41-60): $320 per session (40 minutes)</li>
              <li>Older Age Group (61+): $440 per session (50 minutes)</li>
            </ul>
          </li>
          <li>
            <span>Scars and Large Pores:</span>
            <ul>
              <li>Younger Age Group (18-40): $100 per session (10 minutes)</li>
              <li>Middle Age Group (41-60): $120 per session (20 minutes)</li>
              <li>Older Age Group (61+): $140 per session (25 minutes)</li>
            </ul>
          </li>
          <li>
            <span>Brightening Facial:</span>
            <ul>
              <li>Age group starting from 25-65: $150-$180 per session (1 hour)</li>
            </ul>
          </li>
          <li>
            <span>Hydrating Facial:</span>
            <ul>
              <li>Any age group: $120-$160 per session (1 hour)</li>
            </ul>
          </li>
          <li>
            <span>Firming and Contouring Facial:</span>
            <ul>
              <li>Age group starting from 30-75: $200-$250 per session (1 hour 20 minutes)</li>
            </ul>
          </li>
        </ul>
      </Common>
    </div>
  );
};

export default Solutions;
