import OurServices from "../../components/OurServices/OurServices";
import CommonHeader from "../../components/CommonHeader/CommonHeader";
import img from "../../images/about.png";
import "./Staff.css";
import OurStaff from "../../components/OurStaff/OurStaff";

const Services = () => {
  return (
    <>
      <div className="services_section">
        <OurStaff services={true}></OurStaff>
      </div>
    </>
  );
};

export default Services;
